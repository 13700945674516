import React from 'react';
import './HomePage.css';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const HomePage = () => {

    const data = useStaticQuery(graphql`query HomePageImg {
        file( relativePath: {eq: "Office_A.JPG"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          }
      `)
    return (
                <>
                <div className="home-box">
                    <h1>Welcome to Dermatology Consultants of Asheville</h1>
                    <Img style={{maxWidth:500,width:"100%"}} fluid={data.file.childImageSharp.fluid} alt='Photo of the front of the office.'/>
                    {/* <img className="home-main-img" src={Office} alt="Front facing view of the office entrance"/> */}
                    <h4>Call or E-mail our office to schedule an appointment</h4>
                    <h4>Our Office Hours are 9am-5pm Monday - Friday</h4>
                </div>
                </>
 
    )
};

export default HomePage;

