import React from 'react';
import Layout from '../components/Layout';
import {Typography} from '@material-ui/core';
import HomePage from '../components/HomePage';

export default function Index() {
  return (
    <Layout>
        <Typography align='center' variant='h3' component='h1' gutterBottom>Dermatology Consultants of Asheville</Typography>
        <Typography align='center' variant='h4' component='h2' >Accepting New Patients</Typography>
        <HomePage />
    </Layout>
  );
}
